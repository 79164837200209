<template>
  <div>
    <div class="title-box">
      <img src="@/assets/images/comm/pay-wechat.png" class="logo">
      <div class="title">微信收银台</div>
    </div>
    <van-cell-group title="订单信息">
      <van-card :thumb="require('@/assets/images/mt/thumb.png')" :centered="false" :title="orderName" :price="`${orderAmount.toFixed(2)}`" :num="1" />
      <van-cell title="支付方式：" value="微信支付" />
      <van-cell title="商品金额：" :value="orderAmount.toFixed(2)" />
    </van-cell-group>
    <van-button size="normal" type="info" color="#0DBE64" round block @click.stop="callWechatPay">打开微信付款</van-button>
  </div>
</template>
<script>
import Config from "@/api/service";

export default {
  name: "MTModule",
  components: {},
  data() {
    return {
      wechatPayUrl: null,
      payFinishUrl: null,
      orderGuid: null,
      orderName: null,
      orderAmount: 0,
    };
  },
  methods: {
    //获取路由参数
    getRoutParams() {
      this.orderGuid = this.$route.query.orderGuid;
      this.wechatPayUrl = this.$route.query.wechatPayUrl;
      this.payFinishUrl = this.$route.query.payFinishUrl;
    },
    //获取付款信息
    getPayInfo() {
      var model = {
        OrderGuid: this.orderGuid
      };
      this.$axios
        .post(Config.pay.info, model)
        .then((res) => {
          this.orderName = res.data.Data.OrderName;
          this.orderAmount = res.data.Data.OrderAmount;
          this.callWechatPay();
        });
    },
    //定时刷新付款状态
    timeRefreshPayState() {
      window.setInterval(() => {
        var model = {
          OrderGuid: this.orderGuid
        };
        this.$axios
          .post(Config.pay.state, model)
          .then((res) => {
            if (res.data.Data.State == 1) {
              //已付款，跳转到订单
              window.location.href = this.payFinishUrl;
            }
          });
      }, 3000);
    },
    //调起微信支付客户端
    callWechatPay() {
      window.location.href = this.wechatPayUrl;
    },
  },
  mounted() {
    this.getRoutParams();
    this.getPayInfo();
    this.timeRefreshPayState();
  },
};
</script>

<style scoped lang="less">
.title-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #F6F6F6;
  padding: 10px;

  .logo {
    width: 20px;
  }

  .title {
    font-size: 18px;
    margin-left: 10px;
  }
}
</style>