<template>
  <div class="content-box">
    <div class="title-box">
      <img src="@/assets/images/comm/pay-wechat.png" class="logo">
      <div class="title">微信收银台</div>
    </div>
    <div class="info-box">
      <div class="name">{{ orderName }}</div>
      <div class="payee">收款方：考研商品</div>
      <div class="price">￥{{ orderAmount.toFixed(2) }}</div>
      <div>元</div>
    </div>
    <div class="qrcode-box">
      <div class="qrcode-border">
        <img :src="qeCodeImg" class="qrcode">
        <div class="text">请使用手机微信扫码付款</div>
      </div>
    </div>
  </div>
</template>
<script>
import QRCode from 'qrcode';
import Config from "@/api/service";

export default {
  name: "PCModule",
  components: {},
  data() {
    return {
      qeCodeImg: null,
      qrCodeUrl: null,
      payFinishUrl: null,
      orderGuid: null,
      orderName: null,
      orderAmount: 0,
    };
  },
  methods: {
    //获取路由参数
    getRoutParams() {
      this.orderGuid = this.$route.query.orderGuid;
      this.qrCodeUrl = this.$route.query.qrCodeUrl;
      this.payFinishUrl = this.$route.query.payFinishUrl;
    },
    //获取付款信息
    getPayInfo() {
      var model = {
        OrderGuid: this.orderGuid
      };
      this.$axios
        .post(Config.pay.info, model)
        .then((res) => {
          this.orderName = res.data.Data.OrderName;
          this.orderAmount = res.data.Data.OrderAmount;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //定时刷新付款状态
    timeRefreshPayState() {
      window.setInterval(() => {
        var model = {
          OrderGuid: this.orderGuid
        };
        this.$axios
          .post(Config.pay.state, model)
          .then((res) => {
            if (res.data.Data.State == 1) {
              //已付款，跳转到订单
              window.location.href = this.payFinishUrl;
            }
          })
          .catch((error) => {
            this.$message.error(error.message);
          });
      }, 3000);
    },
    //生成付款二维码
    generateQRCode() {
      QRCode.toDataURL(this.qrCodeUrl, (err, url) => {
        this.qeCodeImg = url;
      })
    }
  },
  mounted() {
    this.getRoutParams();
    this.getPayInfo();
    this.generateQRCode();
    this.timeRefreshPayState();
  },
};
</script>

<style scoped lang="less">
.content-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #eff0f1;
}

.title-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 20px;

  .logo {
    width: 50px;
  }

  .title {
    font-size: 20px;
    margin-left: 10px;
  }
}

.info-box {
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 20px;

  .name {
    font-size: 16px;
    font-weight: bold;
  }

  .payee {
    font-size: 12px;
    margin-left: 20px;
  }

  .price {
    color: #FF6602;
    font-weight: bold;
    font-size: 20px;
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
  }
}

.qrcode-box {
  width: 70%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin-bottom: 20px;
  border: 1px solid lightgray;

  .qrcode-border {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid lightgray;

    .qrcode {
      width: 200px;
      height: 200px;
    }

    .text {
      color: gray;
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
}
</style>